<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :columns="columns"
      :scroll="{ x: 0 }"
      :hideRowSelection="true"
      :is-full="true"
      :current-page="pageNumber"
      size="small"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <a-row :gutter="8">
        <!-- <a-col class="gutter-row" :span="3">
          <a-input
            v-model.trim="SearchDto.ProjectCode"
            :placeholder="l('ProjectHeaderCode')"
          />
        </a-col> -->
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="SearchDto.RealName"
            :placeholder="l('姓名/手机号/身份证')"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <!-- <a-input
              v-model.trim="SearchDto.ProjectName"
              :placeholder="l('ProjectHeaderName')"
            /> -->
          <a-input
            v-model="SearchDto.ProjectName"
            :placeholder="l('ProjectHeaderName')"
            @click="selectProjectModal"
              @change="selectProjectChange"
          >
          </a-input>
        </a-col>
         <a-col class="gutter-row" :span="4">
          <a-range-picker
            :placeholder="[l('购买日期'), l('购买日期')]"
            v-model="startToEndDate"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            style="width: 100%"
            placeholder="是否购买成功"
            v-model="SearchDto.IsBuyStatus"
            allowClear
          >
            <a-select-option
              :value="it.value"
              v-for="(it, i) in isBuyList"
              :key="i"
            >
              {{ it.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <!-- <a-col class="gutter-row" :span="2">
          <a-input
            v-model.trim="SearchDto.IdCard"
            :placeholder="l('请输入身份证号')"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" :span="2">
          <a-input
            v-model.trim="SearchDto.Phone"
            :placeholder="l('请输入手机号码')"
            allow-clear
          />
        </a-col>       -->
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.LGModule.InsurancePurchaseData.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align:right" :span="4">
          <a-button
            v-if="isGranted('Pages.LGModule.InsurancePurchaseData.Export')"
            @click="exportToExcel()"
          >
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";
import { CommonServiceProxy, initInvoice } from "@/shared/common-service";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      SearchDto: {
        ProjectId: "",
        ProjectName: "",
       ProjectCode:"",
       StartDate:"",
       EndDate:"",
       RealName:"",
       IdCard:"",
       Phone:"",
       IsBuyStatus: undefined,
        // CustomerId: "",
       
        SkipCount: 0,
        MaxResultCount: 50,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
       startToEndDate: [],
      columns: [],
      PayCompanys: [], // { value: "", label: "签约公司" }
      isBuyList: [
        // { value: "", label: "开票类型" },
        { value: 1, label: "是" },
        { value: 0, label: "否" },
      ],
    };
  },
  methods: {
    initColumns() {
      let _this = this;
      this.columns = [
        {
          title: "姓名",
          dataIndex: "userName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "phone" },
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "phone" },
        },
        {
          title: "身份证号",
          dataIndex: "icCardNo",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "icCardNo" },
        },
         {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectName" },
        },
         {
          title: "项目编码",
          dataIndex: "projectCode",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectCode" },
        },
        
        {
          title: "是否购买成功",
          dataIndex: "buyResult",
          align: "center",
          // width: 100,
           scopedSlots: { customRender: "buyResult" },
          customRender: (text) => {
            debugger
            return text?"是":"否";
          },
        },
         {
          title: "购买时间",
          dataIndex: "creationTime",
          align: "center",
          sorter: true,
          // fixed: "left",
         customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "creationTime" },
        },
         {
          title: "反馈结果(失败原因)",
          dataIndex: "failReason",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "failReason" },
        },
        // {
        //   title: "开票类型",
        //   dataIndex: "type",
        //   align: "center",
        //   // width: 100,
        //   scopedSlots: { customRender: "type" },
        //   customRender: (text) => {
        //     if (text == 1) {
        //       return "增值税普通发票";
        //     }
        //     if (text == 2) {
        //       return "增值税专用发票";
        //     }
        //     return "";
        //   },
        // },
      ];
    },
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ���� */
    getData() {
      this.spinning = true;
      let options = {
        url:
          this.$apiUrl + "/api/services/app/Project/GetProjectInsurancePurchaseDataPage",
        method: "Post",
         headers: {
          "Content-Type": "application/json-patch+json",
        },
        data: {
          startDate: this.SearchDto.StartDate =this.startToEndDate[0] === null ? undefined : this.startToEndDate[0],
          endDate:this.SearchDto.EndDate =this.startToEndDate[1] === null ? undefined : this.startToEndDate[1],
          projectCode:this.SearchDto.ProjectCode==""?null:this.SearchDto.ProjectCode,
          // projectName:this.SearchDto.ProjectName==""?null:this.SearchDto.ProjectName,
          realName:this.SearchDto.RealName==""?null:this.SearchDto.RealName,
          idCard:this.SearchDto.IdCard==""?null:this.SearchDto.IdCard,
          phone:this.SearchDto.Phone==""?null:this.SearchDto.Phone,
          isBuyStatus:this.SearchDto.IsBuyStatus==undefined?null:this.SearchDto.IsBuyStatus,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
          projectIds:this.SearchDto.ProjectId,
        },
      };
      this.$api.request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                buyResult:item.buyResult?"是":"否",
                 creationTime: moment(item.creationTime).format("YYYY-MM-DD HH:mm:ss"),
                type: initInvoice(item.type),
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/Project/GetProjectInsurancePurchaseDataExcelFile",
          params: {
          startDate: this.SearchDto.StartDate =this.startToEndDate[0] === null ? undefined : this.startToEndDate[0],
          endDate:this.SearchDto.EndDate =this.startToEndDate[1] === null ? undefined : this.startToEndDate[1],
          projectCode:this.SearchDto.ProjectCode==""?null:this.SearchDto.ProjectCode,
          // projectName:this.SearchDto.ProjectName==""?null:this.SearchDto.ProjectName,
          realName:this.SearchDto.RealName==""?null:this.SearchDto.RealName,
          idCard:this.SearchDto.IdCard==""?null:this.SearchDto.IdCard,
          phone:this.SearchDto.Phone==""?null:this.SearchDto.Phone,
          isBuyStatus:this.SearchDto.IsBuyStatus==undefined?null:this.SearchDto.IsBuyStatus,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
               projectIds:this.SearchDto.ProjectId,
          },
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
     selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
                  searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          //   message.success("succed");
          //   console.log(data);
                    let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.SearchDto.ProjectId = projectIds;
          this.SearchDto.ProjectName = projectNames;
          this.getData();
          // this.getTaskByProjectId(0, data.id);
        }
      });
    },
    selectProjectChange(){
      this.SearchDto.ProjectId = "";
      this.SearchDto.ProjectName = "";
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
        this.SearchDto.ProjectId = "";
        this.SearchDto.ProjectName = "";
        this.SearchDto.ProjectCode="";
        this.SearchDto.StartDate = "";
        this.SearchDto.EndDate = "";
        this.SearchDto.RealName="";
        this.SearchDto.IdCard="";
        this.SearchDto.Phone ="";
        this.SearchDto.IsBuyStatus=undefined;
        this.startToEndDate = [];
      //   this.SearchDto.CustomerId = "";
      this.SearchDto.SkipCount = 0;
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getAllPayCompany();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
